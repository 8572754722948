@import 'styles/vars';
@import 'styles/scss/mixins/fluid-font-size';

.container {
  position: relative;
  display: block;
  border-bottom: 0.0625rem solid $primary;
  text-decoration: none !important;

  @media (max-width: $w768) {
    border: none;
    display: flex;
    flex-direction: column;
  }

  &.hovered {
    .hover {
      opacity: 1;
      pointer-events: auto;
    }

    .cartQuick {
      opacity: 1;
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-width: $w768) {
    height: auto;
  }
}

.icon {
  z-index: 3;
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.125rem;

  svg {
    width: 6.75rem;
    height: 2.25rem;
  }

  @media (max-width: $w576) {
    svg {
      width: auto;
      height: 2.5rem;
    }
  }

  .rotate :global(.action_animated) {
    transform-origin: center;
    will-change: transform;
    animation: rotate 7s linear infinite;
  }
}

.icon_grid {
  @media (max-width: $w576) {
    svg {
      width: auto;
      height: 1rem;
    }
  }
}

.poster {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin: 0;

  & > span {
    width: 100% !important;
    height: 100% !important;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: $w768) {
    flex-shrink: 0;
  }
}

.footer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 1.5rem;

  @media (max-width: $w576) {
    margin-top: 1rem;

    &.grid {
      .info {
        margin-right: 0;
      }

      .prices {
        margin-top: 0.5rem;
        align-self: flex-start;
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
      }

      .price {
        margin-right: 0.25rem;
      }
    }

    &.plates {
      .prices {
        align-self: flex-end;
      }
    }
  }
}

.info {
  margin-right: 1rem;
}

.title {
  font-size: $f18;
  font-weight: 400;
  line-height: 1.25;
  color: $primary;
  margin: 0;

  @media (max-width: $w576) {
    font-size: $f14;
  }
}

.descr {
  font-size: $f16;
  line-height: 1.45;
  color: $secondary;
  margin: 0;

  @media (max-width: $w576) {
    font-size: $f12;
  }
}

.priceOld {
  font-size: $f14;
  line-height: 1.5;
  text-decoration: line-through;
  color: $brown;
  margin: 0;

  @media (max-width: $w576) {
    line-height: 1.3;
  }
}

.price {
  font-size: $f18;
  line-height: 1.5;
  color: $primary;
  margin: 0;
  white-space: nowrap;

  @media (max-width: $w576) {
    font-size: $f16;
    line-height: 1.1;
  }
}

.hover {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  background-color: $light;
  margin: 0;

  @media (max-width: $w768) {
    display: none;
  }

  &_wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  & > img {
    object-fit: cover;
  }
}

.goto {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  height: 5.625rem;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 0.75rem;
  color: $primary;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0;
  @include fluid-font-size($f12, $f14, $f16);

  &.bgwhite {
    background-color: $white;
  }
}

.cartQuick {
  border: 1px solid $black05t;
  background: $white50t;
  padding: 0.875rem;
  backdrop-filter: blur(1.4375rem);
  position: absolute;
  bottom: 3.125rem;
  left: 1.25rem;
  right: 1.25rem;
  z-index: 3;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  opacity: 0;
  cursor: pointer;

  @media (max-width: $w768) {
    display: none;
  }
}

.cartQuick__mobile {
  display: none;

  @media (max-width: $w768) {
    display: flex;
    width: 100%;
    min-height: 2.75rem;
    justify-content: center;
    align-items: center;
    padding: 0 0.25rem;
    background: none;
    color: $primary;
    border: 1px solid $primary;
    cursor: pointer;
    font-size: $f12;
    text-transform: uppercase;
    line-height: 1;
    margin-top: auto;

    span {
      position: relative;
      top: -0.0625rem;
      margin-right: 0.25rem;
    }
  }
}

.cartQuick__mobile_grid {
  @media (max-width: $w768) {
    font-size: $f10;
  }
}

.icon-element {
  height: 2.5rem;
  width: 2.75rem;
  padding: 0 0.375rem;
  font-size: 0.875rem;
  background: $light;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  color: $brown;
  font-weight: 500;

  &--kids {
    background: linear-gradient(
      86deg,
      #7852bd -40.52%,
      #e94f9b 30.69%,
      #f0a349 105.93%
    );
    color: $white;
  }

  &--percent {
    width: fit-content;
  }

  @media (max-width: $w768) {
    font-size: 0.625rem;
    height: 1.875rem;
    width: 2rem;

    &--percent {
      width: fit-content;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
